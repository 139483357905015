
const { store, ProductType, Platform, ErrorCode } = window.CdvPurchase;
import {apiCall} from './ApiMiddleware';
import Vue from 'vue';
import i18n from './i18n.js';
import { router } from '../main.js';
import { GlobalEventEmitter } from './GlobalEventEmitter.js';

const PRODUCT_ID_1_WEEK = "PREMIUM_1WEEK";
const PRODUCT_ID_1_MONTH = "PREMIUM_1MONTH";
const PRODUCT_ID_1_YEAR = "PREMIUM_1YEAR";

export function initPurchase() {
        // force debug logs
        store.verbosity = store.DEBUG;

        console.log("CDV Initializing in-app purchase");

        // Register products
        store.register([
            {
                id: PRODUCT_ID_1_WEEK,
                platform: Platform.APPLE_APPSTORE,
                type: ProductType.PAID_SUBSCRIPTION,
            },

            {
                id: PRODUCT_ID_1_MONTH,
                platform: Platform.APPLE_APPSTORE,
                type: ProductType.PAID_SUBSCRIPTION,
            },

            {
                id: PRODUCT_ID_1_YEAR,
                platform: Platform.APPLE_APPSTORE,
                type: ProductType.PAID_SUBSCRIPTION,
            }
        ]);

        // Setup event handlers
        /*store.when().productUpdated(function () {
            console.log('CDV Products loaded from the store:', store.products);
        });*/

        store.when().approved(async function (transaction) {
            console.log('CDV Purchase approved:', transaction);

            // if transaction id is numeric
            if (!isNaN(transaction.transactionId)) {    
                router.push({ name: 'Attivazione in corso' });
                let response = await apiCall('POST', '/subscriptions/appstore/check', {
                    transaction_id: transaction.transactionId,
                });
                if (response.status != 200 && response.status != 0) {
                    console.error('CDV Error checking subscription:', response);
                    Vue.prototype.$vs.notification({
                        title: i18n.$t('common.messages.somethingWentWrong'),
                        text: i18n.$t('profile.invoicing.messages.cannotPay') + ' (' + response.status + ')',
                        color: 'danger',
                        position: 'top-right'
                    });
                } else {
                    router.push({ name: 'Attivata' });
                    GlobalEventEmitter.$emit('loadUserMeta');
                }
            }
            transaction.finish();
        });

        store.when().finished(async function (transaction) {
            console.log('CDV Purchase finished:', transaction);
        });

         // Initialize the store
        store.initialize([{
            platform: Platform.APPLE_APPSTORE,
            options: {
                needAppReceipt: true,
            }
        }]);

}

export function inAppPurchase(productId, loading) {
    try {
        store.verbosity = store.DEBUG;


        console.log('CDV triggering purchase for ios product id:', productId);
        const product = store.get(productId);
        if (!product) {
            console.error('Product not found');

            Vue.prototype.$vs.notification({
                title: i18n.$t('common.messages.somethingWentWrong'),
                text: i18n.$t('profile.invoicing.messages.cannotPay') + ' (404)',
                color: 'danger',
                position: 'top-right'
            });
            loading.close();
            return;
        }
        product.getOffer()?.order()
            .then(data => {
                console.log('CDV Order data:', data);
                loading.close();
            },
            error => {
                loading.close();
                if (error) {
                    if (error.code === ErrorCode.PAYMENT_CANCELLED) {
                        console.log('CDV Payment cancelled by user');
                    } else {
                        console.error('Failed to subscribe(1):', error.message);
                        Vue.prototype.$vs.notification({
                            title: i18n.$t('common.messages.somethingWentWrong'),
                            text: i18n.$t('profile.invoicing.messages.cannotPay') + ' (1)',
                            color: 'danger',
                            position: 'top-right'
                        });
                    }
                }
            });
    } catch (error) {
        Vue.prototype.$vs.notification({
            title: i18n.$t('common.messages.somethingWentWrong'),
            text: i18n.$t('profile.invoicing.messages.cannotPay') + ' (2)',
            color: 'danger',
            position: 'top-right'
        });
        console.error('CDV Failed to subscribe(2):', error.message);
        loading.close();
    }
}