import axios from "axios";
import { CapacitorHttp } from '@capacitor/core';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import i18n from './i18n.js';
import Vue from 'vue';
import {GlobalEventEmitter} from './GlobalEventEmitter.js';
import getEnv from './env.js';

const SESSION_COOKIE_NAME = 'TOURIAL-SESSION';
let SESSION_COOKIE = false;

axios.defaults.withCredentials = true

const sha512 = require('sha512')
const setCookieParser = require('set-cookie-parser');
const baseUrl = (getEnv("BASE_URI") != undefined ? getEnv("BASE_URI") : 'https://tourial.it.wised.it') + '/api/tourial/v1';

async function storeToken(token){
    try {
        await SecureStoragePlugin.set({
            key: SESSION_COOKIE_NAME,
            value: token
        });

        SESSION_COOKIE = false; // force to get the cookie from secure storage again for this instance
    } catch (error) {
        console.error('Error saving session cookie in secure storage:', error);
    }
}

// use CapacitorJS to make native calls, weh content-type is multipart/form-data set dataType to formData
async function nativeCall(method, url, payload, silent = false, contentType = 'application/json', isBlob = false) {
    if(SESSION_COOKIE === false){
        try {
            SESSION_COOKIE = await SecureStoragePlugin.get({ key: SESSION_COOKIE_NAME });
        } catch (error) {
            console.error('Error getting session cookie from secure storage:', error);
            SESSION_COOKIE = null;
        }
    }

    let options = {
        url,
        method: method.toUpperCase(),
        headers: {
            'Content-Type': method.toLowerCase() === 'get' ? undefined : contentType,
        },
        disableRedirects: true
    };

    if (SESSION_COOKIE) {
        options.headers['Cookie'] = `${SESSION_COOKIE_NAME}=${SESSION_COOKIE.value}`;
    }


    if(method.toLowerCase() === 'get'){
        // if params are passed in the payload, they are added to the url
        if(payload){
            console.log('PAYLOAD');
            console.log(payload);
            // remove null/undefined values
            Object.keys(payload).forEach((key) => {
                if (payload[key] === null || payload[key] === undefined || payload[key] === '' || (Array.isArray(payload[key]) && payload[key].length === 0)) {
                    delete payload[key];
                }
            });

            let params = new URLSearchParams();
            Object.keys(payload).forEach((key) => {
                if (Array.isArray(payload[key])) {
                    payload[key].forEach((val) => {
                        params.append(key + '[]', val);
                    });
                } else {
                    params.append(key, payload[key]);
                }
            });

            options.url += '?' + params.toString();
        }
    } else if (contentType === 'multipart/form-data') {
        let formData = new Array();
        for (let key in payload) {
            await new Promise((resolve, reject) => {
                // read file as base64
                const reader = new FileReader();
                reader.onload = () => {

                    formData.push({
                        type: 'base64File',
                        key: key,
                        fileName: payload[key].name,
                        contentType: payload[key].type,
                        value: reader.result.split(',')[1]
                    });
                    resolve();
                };

                reader.onerror = error => {
                    reject(error);
                };

                reader.readAsDataURL(payload[key]);

            });
        }
        options.data = formData;
        options.dataType = 'formData';
    } else if (contentType === 'application/json') {
        options.data = payload;
        options.dataType = 'json';
    } else if(contentType == ''){
        await new Promise((resolve) => {
            //payload is a blob, and we need to convert it to base64
            const reader = new FileReader();
            reader.onload = () => {
                options.data = reader.result.split(',')[1];
                resolve();
            };

            reader.readAsDataURL(payload);
        });
        options.headers['Content-Type'] = 'application/octet-stream';
        options.dataType = 'file';
    }

    if (isBlob) {
        options.responseType = 'blob';
    }

    console.log('OPTIONS');
    console.log(options);

    try {
        let response = await CapacitorHttp.request(options);

        console.log('RESPONSE');
        console.log(response);
        
        // if is blob
        if(isBlob){
            const d = Buffer.from(response.data, 'base64');
            const b = new Blob([d], { type: 'application/octet-stream' });
            response.data = b;
        
            console.log('Blob created successfully:', b);
        }

        // if response header set-cookie is present, save it in secure storage
        const cookieHeader = response.headers['set-cookie'] || response.headers['Set-Cookie'];
        if (cookieHeader) {
            const cookies = setCookieParser.parse(cookieHeader);
            const sessionCookie = cookies.find(cookie => cookie.name === SESSION_COOKIE_NAME);
            if (sessionCookie) {
                await storeToken(sessionCookie.value);
            }
        }

        if(response.status >= 400){
            return handleError({ response: { status: response.status, data: response.data } }, silent);
        }

        return {
            data: response.data,
            status: response.status,
            headers: response.headers
        };
    } catch (error) {
        console.log('ERROR');
        console.log(error.message);
        const { status, data } = error.response || {};
        
        return handleError({ response: { status, data } }, silent);
    }
}

async function call(method, uri, payload, silent = false, contentType = 'application/json', isBlob = false){
    var result;

    if(Vue.prototype.$IS_APP){
        result = await nativeCall(method, uri, payload, silent, contentType, isBlob);
        console.log('RESULT');
        console.log(result);
        return result;
    }
    
    try {
        if(method.toLowerCase() === 'get'){
            result = await axios.get(uri, {
                headers: {
                    'Content-Type': 'application/urlencoded'
                },
                params: payload,
                responseType: isBlob ? 'blob' : undefined
            })
        } else {
            result = await axios[method.toLowerCase()](uri, payload, {
                headers: {
                    'Content-Type': contentType
                },
        })
        }

        // current response url
        console.log(result.request.responseURL);
        
        return result;
    
    } catch (error) {
        return handleError(error, silent);
    }
    
}

async function callMiddleware(method, uri, payload, silent = false, contentType = 'application/json', isBlob = false){
    uri = baseUrl + uri;
    return await call(method, uri, payload, silent, contentType, isBlob);
}

function handleError(error, silent = false){
    error.response.original_status = error.response.status;
    if(error.response.status === 401){
        if(!silent){
            Vue.prototype.$vs.notification({
                color: 'danger',
                position: 'top-right',
                title: 'Qualcosa è andato storto!',
                text: 'Sessione scaduta. Effettua nuovamente il login.'
            })
            GlobalEventEmitter.$emit('loadUserMeta'); // IMPORTANT: It must be in the silent if, otherwise when App.vue calls getUserMeta() it will be called infinitely
        }
        
        error.response.status = 0;
    } else if(error.response.status === 402){
        if(!silent){
            GlobalEventEmitter.$emit('showPaymentRequired');
        }
        error.response.status = 0;
    } else if(error.response.status === 403){
        if(!silent){
            Vue.prototype.$vs.notification({
                color: 'danger',
                position: 'top-right',
                title: 'Qualcosa è andato storto!',
                text: 'Non hai i permessi per effettuare questa operazione.'
            })
        }
        error.response.status = 0;
    } else if(error.response.status >= 500 && error.response.status <= 599 && error.response.status != 508){
        console.log(error.response.status);
        console.log(error.response.data);
        if(!silent){
            Vue.prototype.$vs.notification({
                color: 'danger',
                position: 'top-right',
                title: 'Qualcosa è andato storto!',
                text: 'Errore interno del server. Riprova più tardi.'
            })
        }
        error.response.status = 0;
    }
    return error.response;
}

function googleStatusHandler(status){
    if(status == 'conflict'){
        Vue.prototype.$vs.notification({
            title: i18n.t('common.messages.somethingWentWrong'),
            text: i18n.t('login.messages.google.conflict'),
            color: 'danger',
            position: 'top-right',
            duration: 10000
        });
    } else if(status == 'error'){
        Vue.prototype.$vs.notification({
            title: i18n.t('common.messages.somethingWentWrong'),
            text: i18n.t('login.messages.google.error'),
            color: 'danger',
            position: 'top-right',
            duration: 10000
        });
    } else if(status == 'gone'){
        Vue.prototype.$vs.notification({
            title: i18n.t('common.messages.somethingWentWrong'),
            text: i18n.t('login.messages.google.gone'),
            color: 'danger',
            position: 'top-right',
            duration: 10000
        });
    } else if(status == 'register_succeeded'){
        GlobalEventEmitter.$emit('showTrialStartedDialog');
    }
}


function h(s){
    var h = sha512(s);
    return h.toString('hex');
}
export const apiCall = callMiddleware;
export const coreCall = call;
export const storeTokenInSecureStorage = storeToken;
export const handleGoogleStatus = googleStatusHandler;
export const hash = h;
export const baseUri = baseUrl;